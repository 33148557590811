import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h3: "h3",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "The router is unique to Blitz, and is what makes native ES modules work seamlessly with no opt-in from any framework or library, and also allows Blitz to progressively adopt another view layer if needed."
    }), "\n", _jsx(_components.p, {
      children: "It makes a few core assumptions:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "There is a React component or DOM element as the main entry point of a route. This means that code-splitting is done by route."
      }), "\n", _jsxs(_components.li, {
        children: ["The route has a ", _jsx(_components.code, {
          children: "fetchData"
        }), " method, which must use ", _jsx(_components.code, {
          children: "getData"
        }), " to get all data that is needed on the route. ", _jsx(_components.strong, {
          children: "This means that data should NOT be loaded from components."
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "url-is-the-source-of-truth",
      children: _jsx(_components.a, {
        href: "#url-is-the-source-of-truth",
        children: "URL is the Source of Truth"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The URL should serialize everything that is required to render what the user is seeing. What this means is that ", _jsx(_components.strong, {
        children: "one must set the URL to change the view, then the view must follow"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Examples of anti-patterns:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fetching data from a component then updating the URL (WRONG, only fetch data from a route)"
      }), "\n", _jsx(_components.li, {
        children: "Changing the view significantly without serializing it in the URL, for example, having tabs that don't change the URL."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "useisloaded-hook",
      children: _jsx(_components.a, {
        href: "#useisloaded-hook",
        children: "useIsLoaded() hook"
      })
    }), "\n", _jsx(_components.p, {
      children: "There is a convenient way to handle loading/error states:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "const isLoaded = useIsLoaded();\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Returns ", _jsx(_components.code, {
        children: "Boolean"
      }), "."]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "false"
        }), ": loading"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "true"
        }), ": done (but check for empty)"]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "routes-array",
      children: _jsx(_components.a, {
        href: "#routes-array",
        children: "Routes Array"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["All routes are stored in a central location, the ", _jsx(_components.code, {
        children: "routes"
      }), " array. This array can be modified in runtime."]
    }), "\n", _jsx(_components.h3, {
      id: "legacy",
      children: _jsx(_components.a, {
        href: "#legacy",
        children: "Legacy"
      })
    }), "\n", _jsx(_components.p, {
      children: "Historically, routes were defined as a flat array of objects, which contained the path, component, and a method to get data for the route. This has been preserved."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
